

import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';

@Component
export default class CalculatedPricesTooltip extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;

    @Prop({
        type: String,
    })
    private provider!: string;

    get calculations() {
        return this.carsService.getExtraExpensesByProvider(this.provider).map(item => item.description);
    }
}
