
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScreenshotPopup from '@/modules/cars/components/screenshot-popup/screenshot-popup.vue';

@Component({
    components: { ScreenshotPopup },
})
export default class RateScreenshotModalPage extends Vue {
    @Prop({
        type: String,
    })
    private url?: string;

    @Prop({
        type: Date,
    })
    private date?: Date;

    @Prop({
        type: String,
    })
    private rectangleUniqueness?: string;

    @Prop({
        type: String,
    })
    private provider?: string;
}
